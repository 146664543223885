import { Store } from "vuex";
import { getModule } from "vuex-module-decorators";
import Blog from "~/store/blog";
import CaclulatorStore from "~/store/calculator";
import Checkout from "~/store/checkout";
import EsyoilStore from "~/store/esyoil";
import Federalstates from "~/store/federalstates";
import HistoryDataStore from "~/store/historydata";
import Oilprice from "~/store/oilprice";
import Optout from "~/store/optout";
import Partner from "~/store/partner";
import PartnerUserCommentsStore from "~/store/partnerUserComments";
import PriceComparison from "~/store/priceComparison";
import Schwarmometer from "~/store/schwarmometer";
import Tankreinigung from "~/store/tankreinigung";
import User from "~/store/user";

let _CaclulatorStore: CaclulatorStore;
let _BlogStore: Blog;
let _Checkout: Checkout;
let _Esyoil: EsyoilStore;
let _Federalstates: Federalstates;
let _Historydata: HistoryDataStore;
let _Oilprice: Oilprice;
let _Optout: Optout;
let _Partner: Partner;
let _Schwarmometer: Schwarmometer;
let _Tankreinigung: Tankreinigung;
let _User: User;
let _PriceComparison: PriceComparison;
let _PartnerUserCommentsStore: PartnerUserCommentsStore;

function initialiseStores(store: Store<any>): void {
  _CaclulatorStore = getModule(CaclulatorStore, store);
  _BlogStore = getModule(Blog, store);
  _Checkout = getModule(Checkout, store);
  _Esyoil = getModule(EsyoilStore, store);
  _Federalstates = getModule(Federalstates, store);
  _Historydata = getModule(HistoryDataStore, store);
  _Oilprice = getModule(Oilprice, store);
  _Optout = getModule(Optout, store);
  _Partner = getModule(Partner, store);
  _Schwarmometer = getModule(Schwarmometer, store);
  _Tankreinigung = getModule(Tankreinigung, store);
  _User = getModule(User, store);
  _PriceComparison = getModule(PriceComparison, store);
  _PartnerUserCommentsStore = getModule(PartnerUserCommentsStore, store);
}

export {
  initialiseStores,
  _BlogStore as BlogStore,
  _CaclulatorStore as CalculatorStore,
  _Checkout as CheckoutStore,
  _Esyoil as EsyoilStore,
  _Federalstates as Federalstates,
  _Historydata as History,
  _Oilprice as Oilprice,
  _Optout as Optout,
  _Partner as Partner,
  _PriceComparison as PriceComparison,
  _Schwarmometer as Schwarmometer,
  _Tankreinigung as Tankreinigung,
  _User as User,
  _PartnerUserCommentsStore as PartnerUserCommentsStore,
};
