import axios from "axios";
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";

export type UserComment = {
  _id: string;
  customerStatement: string;
  esyoilStatement: string;
  partnerStatement: string;
  updatedAtByCustomer: string;
  businessCustomerId: string;
  orderDate: string;
  rating: number;
  bcFullName: string;
  customerStatementLength: number;
};

export type UserCommentsState = {
  loadVolume: number;
  loadedAmount: number;
  data: UserComment[];
};

type FetchOptions = {
  page: string;
  body: {
    end: string;
    start: string;
    sortBy: "bad" | "best" | "old" | "new";
    sortDesc: string;
    businessCustomerId: string | string[];
  };
  clear: boolean;
};

@Module({
  name: "partnerUserComments",
  namespaced: true,
  stateFactory: true,
})
export default class PartnerUserCommentsStore extends VuexModule {
  userComments: UserCommentsState = {
    loadVolume: 0,
    loadedAmount: 0,
    data: [],
  };

  @Mutation
  clearComments() {
    this.userComments = {
      loadVolume: 0,
      loadedAmount: 0,
      data: [],
    };
  }

  @Mutation
  setComments(userComments) {
    this.userComments.loadVolume = userComments.metadata.total;
    this.userComments.loadedAmount = userComments.metadata.limit;
    userComments.data.forEach((comment) => {
      this.userComments.data.push(comment);
    });
  }

  @Action
  async loadComments(options: FetchOptions) {
    if (options.clear === true) {
      this.context.commit("clearComments");
    }
    try {
      const result = await axios.post(
        `${process.env.BACKBONE_ESYOIL}${process.env.PARTNER_RATING}/5/${options.page}`,
        options.body,
      );
      this.context.commit("setComments", result.data);
    } catch (e) {
      console.warn(e);
    }
  }
}
